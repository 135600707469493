import { WolfApiData } from '@/components/wrappers/molds/TableOptions';
import { Alert } from '@/services/notification';
import { Group } from '@/store/modules/group/group';
import { Process } from '@/store/modules/process/process';

export class NewProcessService {
	store: any
	
	public alert: Alert

	constructor(store: any){
		this.store = store
		this.alert = new Alert(this.store)
	}

	async fetchUser(idUser: string): Promise<any> {
		await this.store.dispatch('user/fetchUser', idUser)
		
		if(this.store.getters['user/error']){
			this.alert.apiMessage("Não foi possível encontrar usuário.", this.store.getters['user/error'], "danger")
			return false
		}else{
			return this.store.getters['user/user']
		}
	}

	async fetchGroups(): Promise<Group[]> {
		await this.store.dispatch('group/fetchGroups',"")
		
		if(this.store.getters['group/error']){
			this.alert.apiMessage("Não foi possível carregar os grupos.", this.store.getters['group/error'], "danger")
			return []
		}else{
			return this.store.getters['group/groups'].list
		}
	}

	async createProcess(newProcess: any): Promise<boolean> {
		await this.store.dispatch('process/createProcess', newProcess)
		
		if(this.store.getters['process/error']){
			this.alert.apiMessage("Não foi possível criar processo.", this.store.getters['group/error'], "danger")
			return false
		}else{
			this.alert.success("Processo criado com sucesso.")
			return true
		}
	}

}