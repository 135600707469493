import { Component, Vue } from 'vue-property-decorator'

//molds
import { User } from '@/molds/User';
import { Group } from '@/store/modules/group/group';
import { FieldSearchModel } from '@/molds/FieldSearchModel';
import { Form, Input, Option } from '@/components/wrappers/molds/Form';

//utils
import { isEmpty } from '@/utils';

//components
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'
import PrivateContent from '@/components/privateContent/PrivateContent.vue'

//services
import { NewProcessService } from '@/services/newProcessService';

//wrappers
import { WForm, Box, WSubtitle, WColumns, WColumn, Container, WHr, WButton, SizedBox, Loader } from '@/components/wrappers';
import { Process } from '@/store/modules/process/process';

@Component({
	components: {
    PrivateContent,
    FieldSearch,
    WForm,
    WColumns,
    WColumn,
    Container,
    Box,
    WHr,
    WButton,
		WSubtitle,
		SizedBox,
		Loader
  }
})
export default class NewProcess extends Vue {
	clear = false
	groupsApi!: Group[]
	isLoader = true;
	idUser!: string

	newProcessService = new NewProcessService(this.$store)

	mounted(){
		document.title = 'Novo Processo'
		this.fetchGroups()
	}

  fieldSearchConfig: FieldSearchModel = {
    label: 'Usuário de Origem *',
    placeholderFieldResult: 'Usuário de Origem',
    modalTitle: 'Buscar Usuário',
    inputField: 'name',
    showColum: 'name',
		endpoint: '/user',
		urlQueryKey: '_limit=10&name',
		closeAfter: true
  }

	selectedFieldSearch(user: User): void{
    this.idUser = user.idUser
  }

  userOrigem = {
    name: '',
  }

  isInvalidField = {
    usuarioOrigem: false
  }

	async fetchGroups(): Promise<void> {
		this.isLoader = true
		const response = await this.newProcessService.fetchGroups()
		if(response){
			this.groupsApi = response
		}
		this.isLoader = false
	}

	clearForm(): void {
		if(this.clear){
			this.clear = false
		}else{
			this.clear = true
		}
	}

	dispatchForm(inputs: any): void {
		if(isEmpty(this.idUser)){
			this.newProcessService.alert.warning("Campo <b>Usuário de Origem</b> é obrigatório")
		}else{
			inputs['idUserOrigin'] = this.idUser
			if(isEmpty(inputs['idGroupOrigin'])){
				delete inputs['idGroupOrigin']
			}
			this.createProcess(inputs)
		}
	}

	async createProcess(process: Process): Promise<void> {		
		this.isLoader = true
		if(await this.newProcessService.createProcess(process)){
			this.clear = !this.clear
		}
		this.isLoader = false
	}

	get groups(): Option[] {
		if(!this.isLoader && this.groupsApi != null){
			const option: Option[] = [{
				label: "Selecione",
				value: ""
			}]
			for (let i = 0; i < this.groupsApi.length; i++) {
				option.push({
					label: this.groupsApi[i].name,
					value: this.groupsApi[i].idGroup
				})
			}
			return option
		}
		return []
	}

	get form(): Form {
		return {
			indent: true,
			grid:[
				[
          {
						fieldName: "type",
						label:"Tipo  *",
						type: "select",
						maxlength: 15,
						required: true,
						value: "processo",
						options: [
							{
								label: "Processo",
								value: "processo"
							},
							{
								label: "Requerimento",
								value: "requerimento"
							},
							{
								label: "Solicitação",
								value: "solicitacao"
							},
							{
								label: "Reclamação",
								value: "reclamacao"
							},
							{
								label: "Sugestão",
								value: "sugestao"
							},
							{
								label: "e-Sic",
								value: "esic"
							},
						]
					},
          {
						fieldName: "idGroupDestiny",
						label:"Grupo Destino  *",
						type: "select",
						maxlength: 15,
						required: true,
						value: "",
						options: this.groups
					},
          {
						fieldName: "idGroupOrigin",
						label:"Grupo Origem",
						type: "select",
						maxlength: 15,
						value: "",
						options: this.groups
					},
				],
				// [
				// 	{
				// 		fieldName: "internalExternal",
				// 		label: "Identificação Externa",
				// 		type: "text",
				// 		maxlength: 100,
				// 		placeholder:"Identificação Externa",
				// 	},
				// ],
        [
					{
						fieldName: "subject",
						label: "Assunto",
						type: "text",
						maxlength: 150,
						placeholder:"Assunto",
					},
				],
        [
					{
						fieldName: "details",
						label: "Detalhes *",
						type: "textarea",
						maxlength: 300,
						placeholder:"Detalhes",
						required: true,
					},
				]
			]
		}
	}

}
